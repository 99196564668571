import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

function Profile() {
    const {user, isAuthenticated} = useAuth0();

    //                {JSON.stringify(user, null, 2)}

    return  (
        isAuthenticated && (
<>
            <div className="col col-md-2">
                <img src={user.picture} ></img>
            </div>
            <div className="col col-md-4">
                <span>nickname:{user.nickname}</span> <br/>
                <span>name: {user.name}</span><br/>
                <span>email: {user.email}</span><br/>

            </div>

</>
        )


    )

}

export default Profile;
