import React from 'react'
import {useState, useEffect} from 'react'
import {MY_IP_ADDRESS} from './IPAddress'

const ASTROIP_KEY = process.env.REACT_APP_ASTROIP_KEY;

function IPAddressInfo(props) {

    //console.log("my ip address",MY_IP_ADDRESS);
    const[error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [item, setItem] = useState([]);
    const [tz, setTZ] = useState([]);
    const [asn, setASN] = useState([]);
    const [itemBase, setItemBase] = useState([]);
    const [cityURL, setCityURL] = useState("");

    useEffect(() => {
        let my_url = 'https://api.astroip.co/'+MY_IP_ADDRESS+'?api_key='+ASTROIP_KEY+'&hostname=true'; 
        fetch(my_url)
//        fetch('https://cors-anywhere.herokuapp.com/https://api.astroip.co/'+MY_IP_ADDRESS+'?api_key='+ASTROIP_KEY+'&hostname=true')
        .then(res =>res.json())
        .then(
            (result) => {
                setIsLoaded(true);
                //console.log(result);
                console.log(result.geo.longitude)
                //console.log(result.geo.length)
                setItemBase(result);
                setItem(result.geo);
                setTZ(result.timezone);
                setCityURL( `https://www.bing.com/maps?q=${result.geo.latitude}%2C+${result.geo.longitude}`  )       ;       

                if (result.asn.region_name===""){
                    result.asn.region_name="unknown"
                }
                setASN(result.asn);
            },
        (error) => {
            setIsLoaded(true);
            setError(error);
        }
        )
        
    }, [])

    if (error) {
        return <span className="badge badge-danger">{error.message}</span>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            // <ul>
            //   {items.map(item => (
            //     <li key={item.id}>
            //       {item.name} {item.price}
            //     </li>
            //   ))}
            // </ul>
            <div className="card">
                <div className="card card-inverse card-info text-center">
                <h4 className="card-title">{itemBase.hostname}</h4>
                <h6 className="card-subtitle mb-2 text-muted" >ASN: <span className="badge badge-default">{asn.asn}</span></h6>    
                <h6 className="card-subtitle mb-2 text-muted" >Organization: <span className="badge badge-default">{asn.organization}</span></h6>   
                <h6 className="card-subtitle mb-2 text-muted" >Route: <span className="badge badge-default">{asn.route}</span></h6>   

                <h6 className="card-subtitle mb-2 text-muted" >Country: <span >{item.country_name}</span></h6>
            <h6 className="card-subtitle mb-2 text-muted" >Region: <span className="badge badge-success">{item.region_name}</span></h6>
            <h6 className="card-subtitle mb-2 text-muted" >City: <span className="badge badge-success">{item.city}</span></h6>
            <h6 className="card-subtitle mb-2 text-muted" >Latitude/Longitude: <span className="badge badge-default">{item.latitude}, {item.longitude}</span></h6>       
            <h6 className="card-subtitle mb-2 text-muted"><a target="_blank" href={cityURL}> {item.latitude}, {item.longitude} </a> </h6>      

            
                </div>


        </div>
          );
    }

}

export default IPAddressInfo;