import {useState, useEffect} from 'react'
import {Badge,Button,Card, ListGroup} from 'react-bootstrap'

//import axios from 'axios'

const ASTROIP_KEY = process.env.REACT_APP_ASTROIP_KEY;
var MY_IP_ADDRESS = "";

const current_ip = function(ip_address) {
    fetch('https://api.astroip.co/'+ip_address+'/?api_key='+ASTROIP_KEY)
    .then(res => res.json())
    .then((data) => {
      //  console.log(data);
        return(data);
    });
}


export function getUserAgent() {

    return(<>



    <Card >
        <Card.Header>
            Additional Information
        </Card.Header>
        <Card.Body>
            <Card.Title>
                Your Browser
            </Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
               testing of various settings
            </Card.Subtitle>

            <ListGroup>
                <ListGroup.Item>
                <Badge variant="info">AppVersion</Badge> {navigator.appVersion}
                </ListGroup.Item>
                <ListGroup.Item>
                <Badge variant="info">Cookies Enabled</Badge> {navigator.cookieEnabled}
                </ListGroup.Item>
                <ListGroup.Item>
                <Badge variant="info">Platform</Badge> {navigator.platform}
                </ListGroup.Item>
                <ListGroup.Item>
                <Badge variant="info">User-agent</Badge> {navigator.userAgent}
                </ListGroup.Item>            </ListGroup>


        </Card.Body>

    </Card>

    </>
    );    

}

export function getIPAddressInfo(props) {

    return(
        <>
            
        </>
    ) 
};



export  function  getIPClient() {

   // const [ip, setIP] = useState([]);

    var ip_address = "1234";
    let result =  fetch('https://api.ipify.org?format=json')
    .then(res=>res.json())
    .then((data) => {
        //console.log(data.ip);
        ip_address = data.ip;
        MY_IP_ADDRESS = data.ip;
        //console.log("ip address",MY_IP_ADDRESS)
        //return(<div>test</div>);
        
    });



    return(<>
        <div>
            get ipclient results here - {MY_IP_ADDRESS}
        </div>
        </>
    )
};
