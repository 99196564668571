import React from 'react'

export const APP_VERSION = "v .097";

export default function Version() {
    return (
        <div>
            {APP_VERSION}

            
        </div>
    )
}

