import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Form, Button } from "react-bootstrap";
import APICalls from './utils/APICalls'


let api1 = "https://ifsc.razorpay.com/";
//api1=api1+"IDFB0060603";


function SearchScreen(props) {
    const {user, isAuthenticated} = useAuth0();

    let [searchString,setSearchString] = React.useState("initial value here");
    var [searchResult,setSearchResult] = React.useState([]);

    let changeValue = e => setSearchString(e.target.value);

    //setSearchResult("test");
    //console.log(new Date());

    function apiSearch(props) {
        fetch(api1+searchString)
        .then(res => res.json())
        .then(
            (data) => {
            console.log(data);
            setSearchResult(data);

        });        

    }


    return  (
        isAuthenticated && ( <>

<Form>
    <Form.Group >
        <Form.Label>Search for</Form.Label>
        <Form.Control type="text" value={searchString} onChange={changeValue} placeholder="Enter some text"/>
    </Form.Group>
    <Button className="m-2" variant="primary" onClick={apiSearch} >
        Submit 
    </Button>
    {searchString}
  

    - {searchResult.CONTACT} -  {searchResult.ADDRESS}
    


</Form>


</>
        )


    )

}

export default SearchScreen;
