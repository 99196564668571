import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

function LogoutButton() {
    const {logout} = useAuth0();
    const {user, isAuthenticated} = useAuth0();

    return  (
        isAuthenticated && 
        (<button className="btn btn-warning" onClick={()=> logout()}>Logout</button>)

    )
    
    
}

export default LogoutButton;
