import React from "react";
import {Alert,Button} from 'react-bootstrap'
import {useState} from 'react'

function Header() {

    return (
    <div className="container container-fluid">
   
        <nav className="navbar fixed-top navbar-inverse bg-primary bg-transparent">
            <a className="navbar-brand" href="#">Fixed top</a>
        </nav>
    </div>
    )
}

function AlertDismissible() {
    const [show, setShow] = useState(true);
  
    return (
      <>
        <Alert show={show} variant="info">
          <Alert.Heading>Welcome to my auth0 demo</Alert.Heading>
          <p>
            You can log in with Google, or your email/password combination. 
            It also shows some basic information about your browser.
            It doesn't really do anything else yet.  
          </p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button onClick={() => setShow(false)} variant="outline-success">
              OK
            </Button>
          </div>
        </Alert>
  
        {!show && <Button size="sm" variant="info" onClick={() => setShow(true)}>Show Alert</Button>}
      </>
    );
  }
  
  //render(<AlertDismissible />);

export default Header;
export {AlertDismissible};
