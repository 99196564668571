import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Form, Button } from "react-bootstrap";


const WEATHER_KEY = process.env.REACT_APP_OPENWEATHERMAP_KEY;

let api1 = "";

api1="https://api.openweathermap.org/data/2.5/weather?appid="+WEATHER_KEY+"&units=imperial&q="



function WeatherSearch(props) {
    const {user, isAuthenticated} = useAuth0();
    const [error, setError] = React.useState(null);

    let [searchString,setSearchString] = React.useState("San Diego");
    var [searchResult,setSearchResult] = React.useState([]);

    let changeValue = e => setSearchString(e.target.value);


    function apiSearch(props) {
        let newstring = api1+searchString;
        //console.log(newstring);
        newstring=encodeURI(newstring);
        //console.log(newstring);
        fetch(newstring)
        .then(res => res.json())
        .then(
            (data) => {
            //console.log(data);
                props.searchResult = data;
                let coord = props.searchResult.coord;
                let main = props.searchResult.main;
              //  console.log(main);
                //console.log(typeof(main.temp));
                main.temp = Math.round(main.temp);
                main.feels_like = Math.round(main.feels_like);

                let _name = props.searchResult.name;
                let _sys = props.searchResult.sys;
                let _weather = props.searchResult.weather[0];
                let _weather_ico = 'https://openweathermap.org/img/wn/'+_weather.icon+'@2x.png';
                let _city_url = `https://www.bing.com/maps?q=${coord.lat}%2C+${coord.lon}`
            
            setSearchResult(<>


               


<div className="card p-3">
	  <div className="card-block">
	    <h4 className="card-title">{_name}, {_sys.country} </h4>
        <h4 className="font-weight-bold" >{main.temp}&deg;F   </h4>

        <h5  >Feels like: {main.feels_like}&deg;F </h5>
                    <span>{_weather.main} <img src={_weather_ico}/> </span>

        <h6 className="card-subtitle mb-2 text-muted"><a target="_blank" href={_city_url}>{coord.lat}, {coord.lon} </a> </h6>        
	     
                
                   
     
	  
	  </div>
	</div>

             </>   );


        },
        
        (error) => {
            setError(error)
           

        });        

    }

    if (error) {
        return ( <>
        <div>{error}</div>
        </>

        )
    }
    else {

        return  (
            isAuthenticated && ( <>
    
    <Form>
        <Form.Group >
            <Form.Label><b>City Weather Search</b></Form.Label>
            <Form.Control type="text" value={searchString} onChange={changeValue} /> 
            <Button className="m-2" variant="success" onClick={apiSearch} >
            Submit 
        </Button>

             {searchResult}

        </Form.Group>


       
    
    
    </Form>
    
    
    </>
            )
    
    
        )

    }

   

}

export default WeatherSearch;
