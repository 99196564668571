import React from 'react'
import {getUserAgent} from "../components/utils/Utils"

//console.log(getUserAgent());

export default function UserAgent() {
    return (
        <div>
            
            <div>{getUserAgent()}</div>
            
        </div>
    )
}
