
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginButton from './components/LoginButton';
import LogoutButton from './components/LogoutButton';
import Profile from './components/Profile';
import {Header,AlertDismissible} from './components/Header';
import UserAgent from './components/UserAgent'
import IPAddressInfo from './components/IPAddressInfo';
import  IPClient  from './components/IPClient';
import IPAddress from './components/IPAddress';
import {APP_VERSION} from './components/Version'
import AboutButton from './components/AboutComponent'
import {Badge,Button,Card, ListGroup} from 'react-bootstrap'
import SearchScreen from './components/SearchScreen';
import WeatherSearch from './components/WeatherSearch';
import CurrentLocation from './components/CurrentLocation';

function App() {
  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark static-top">
    <div className="container">
      <a className="navbar-brand" href="#">vxl auth0 {APP_VERSION}</a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarResponsive">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item active">
            <a className="nav-link" href="#">Home
              <span className="sr-only">(current)</span>
            </a>
          </li>
          <li className="nav-item">
            <AboutButton></AboutButton>
          </li>

        </ul>
      </div>
    </div>
  </nav>


    <div className="container container-fluid">

      <div className="row mt-2">
       
      <AlertDismissible></AlertDismissible> &nbsp;
        <LoginButton></LoginButton> &nbsp;
         <LogoutButton></LogoutButton> 
       
      </div>
      <div className="row">
        
        <Profile></Profile>
        
      </div>

      <div className="row">
      <div className="col col-md12">

          <CurrentLocation></CurrentLocation>

      </div>
      </div>

      <div className="row">
        <div className="col col-md-8">
        <SearchScreen></SearchScreen>
        <WeatherSearch></WeatherSearch>

        </div>
      </div>

      <div className="row">
      <div className="col col-md12">
        <Card className="mt-2 p-2">
        <IPAddress></IPAddress>

        </Card>

      </div>
    </div>


<div>&nbsp;</div>
<div className="row">
    <div className="col col-md12">
        <IPAddressInfo ipaddress="0.1.1.1"></IPAddressInfo>
    </div>
    </div>

      <div className="row">
      <div className="col col-md10">
        &nbsp;
        <UserAgent></UserAgent>
        </div>
      </div>



    </div>
    </>
  );
}

export default App;
