//<a class="nav-link" href="#">About</a>
import React from "react";
import {useState, useEffect} from 'react'
import { Modal, Button } from "react-bootstrap";


function AboutButton() {
    const [isOpen, setIsOpen] = React.useState(false);

    const showModal = () => {
      setIsOpen(true);
    };
    
    const hideModal = () => {
      setIsOpen(false);
    };
    
    function handleClick(e) {
        e.preventDefault();
        //var d = document.getElementById("about1");
        //console.log("Clicked about");
    }


    return (

<>

        <a  id="about1" className="nav-link" href="#" onClick={showModal}>About </a>

        <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>vxl auth0 example</Modal.Title>
        </Modal.Header>
        <Modal.Body>This is my demo of the autho0 authorization service.</Modal.Body>
        <Modal.Footer>
          <Button  onClick={hideModal}>OK</Button>
          <Button variant="warning">Save</Button>
        </Modal.Footer>
      </Modal>


        </>
    )

}

export default AboutButton;