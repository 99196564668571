import React from 'react'
import {useState, useEffect} from 'react'
import {Badge,Button,Card, ListGroup} from 'react-bootstrap'


export default function CurrentLocation() {

    const [isLoaded, setIsLoaded] = useState(false);
    const [ip, setIP] = useState([]);
    const [current_longitude, setLongitude] = useState("");
    const [current_latitude, setLatitude] = useState("");
    const[error, setError] = useState(null);    
    const [cityURL, setCityURL] = useState("");
    const [locationAllowed, setLocationAllowed] = useState("");

    useEffect(() => { 


        setError(true)
        navigator.geolocation.getCurrentPosition(function(position) {

            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);
            setCityURL( `https://www.bing.com/maps?q=${position.coords.latitude}%2C+${position.coords.longitude}`  )       ;     
            setError(false)
          },
          function(errorCallback) {
            setLocationAllowed("Location not allowed.");

          }

          );




    }, [])




    if (error) {
           return( <div></div> )
    }
    else {

        return (

            <Card className="mt-2 p-2">
            <h6 >Your current location is <a target="_blank" href={cityURL}> {current_latitude},{current_longitude} </a></h6>
        </Card>

        )        
        
    }

}
