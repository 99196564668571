import React from 'react'
import {useState, useEffect} from 'react'

export var MY_IP_ADDRESS = "";

function IPAddress() {

    const[error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [ip, setIP] = useState([]);

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
//        fetch('https://cors-anywhere.herokuapp.com/http://api.ipify.org/?format=json')
        .then(res =>res.json())
        .then(
            (result) => {
                setIsLoaded(true);
                MY_IP_ADDRESS = result.ip
                setIP(result);
            },
        (error) => {
            setIsLoaded(true);
            setError(error);
        }
        )
        
    }, [])

    if (error) {
        return <span>{error.message}</span>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (

            <span>
                <h6 >IP Address: <span className="badge badge-success">{ip.ip}</span></h6>
            </span>
        )
    }

}

export default IPAddress;